<template>
  <b-card-body>
    <div class="mt-4 mb-2 text-center">
      <h4>Benzer Ürünler</h4>
      <b-card-text>Müşteriler bu ürünlere de baktı</b-card-text>
    </div>

    <!-- Swiper -->
    <swiper
      class="swiper-responsive-breakpoints px-4 py-2"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(product,index) in products"
        :key="index"
      >
        <!-- <b-link :to="{ name: 'apps-e-commerce-product-details', params: { slug: slugify(product.name) + '-' + product.id} }" router-tag="li" replace> -->
          <b-link>
          <div class="item-heading">
            <h5 class="text-truncate mb-0">
              {{ product.name }}
            </h5>
            <small class="text-body">by {{ product.brand }}</small>
          </div>
          <div class="img-container w-100 mx-auto py-75">
            <b-img
              :src="product.image"
              style="height:220px;width:80%"
              fluid
              @click="yenile(product.id,product.name)"
            />
          </div>
          <div class="item-meta">
            <ul class="unstyled-list list-inline mb-25">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                  class="mr-25"
                  :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
              <li v-if="product.ratingScore">({{product.ratingScore.totalCount ||''}})</li>
            </ul>
            <p class="card-text text-primary mb-0">
              {{ product.price }}
            </p>
          </div>
        </b-link>
      </swiper-slide>

      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BCardText, BImg, BLink,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { watch } from '@vue/composition-api'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from '../e-commerce-shop/useECommerceShop'
import store from '@/store'
export default {
  components: {
    BCardBody,
    BCardText,
    BImg,
    BLink,

    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods :{
    yenile(productId,name){
      // store.dispatch('app-ecommerce/fetchProduct', { productId }).then(response => {
      //   this.data = response.data.product
      //   //this.selectedColor = this.product.color
      //   })
      this.$router.replace({ name: 'apps-e-commerce-product-details', params: { slug: this.slugify(name) + '-' + productId} })
      location.reload()
      
    },
    slugify (text) {
      var trMap = {
        'ş': 's', 'Ş': 'S', 'ı': 'i', 'İ': 'I', 'ç': 'c', 'Ç': 'C', 'ü': 'u',
        'Ü': 'U', 'ö': 'o', 'Ö': 'O', 'ğ': 'g', 'Ğ': 'G'
      };
      for(var key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
      }
      return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
                .replace(/\s/gi, ' ') // convert spaces to dashes
                .replace(/[-]+/gi, '-')
                .replace(/ /g, '-')
                .toLowerCase() // trim repeated dashes
    }

  },
  created(){
    this.fetchProducts({
        q: '',
        categories: this.data.categoryName,
        brand: '',
        gender:'',
        sortBy: 'featured',
        page: 1,
        perPage: 30,
    }).then(response => {
          this.products = response.data.products
          this.totalProducts = response.data.total
        });
  },
    
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()


    const { products, fetchProducts } = useShopRemoteData()
    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        categories: filters.value.categories ||'demo',
        brand: filters.value.brands ||'',
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          products.value = response.data.products
          totalProducts.value = response.data.total
        })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }

    /* eslint-disable global-require */
    const relatedProducts = [
      // {
      //   name: 'Apple Watch Series 6',
      //   brand: 'Apple',
      //   price: 399.98,
      //   rating: 4,
      //   image: require('@/assets/images/elements/apple-watch.png'),
      // },
      // {
      //   name: 'Apple MacBook Pro - Silver',
      //   brand: 'Apple',
      //   price: 2449.49,
      //   rating: 2,
      //   image: require('@/assets/images/elements/macbook-pro.png'),
      // },
      // {
      //   name: 'Apple HomePod (Space Grey)',
      //   brand: 'Apple',
      //   price: 229.29,
      //   rating: 3,
      //   image: require('@/assets/images/elements/homepod.png'),
      // },
      // {
      //   name: 'Magic Mouse 2 - Black',
      //   brand: 'Apple',
      //   price: 90.98,
      //   rating: 5,
      //   image: require('@/assets/images/elements/magic-mouse.png'),
      // },
      // {
      //   name: 'iPhone 12 Pro',
      //   brand: 'Apple',
      //   price: 1559.99,
      //   rating: 4,
      //   image: require('@/assets/images/elements/iphone-x.png'),
      // },
    ]
    /* eslint-disable global-require */

    return {
      swiperOptions,
      relatedProducts,
      
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      products,
      fetchProducts,
      totalProducts
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
