<template>
  <div class="item-features">
    <div class="row text-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="AwardIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            100% Orjinal
          </h4>
          <!-- <p class="card-text">
            Chocolate bar candy canes ice cream toffee. Croissant pie cookie halvah.
          </p> -->
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ClockIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            10 günde İade/Değişim
          </h4>
          <!-- <p class="card-text">
            Marshmallow biscuit donut dragée fruitcake. Jujubes wafer cupcake.
          </p> -->
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ShieldIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            1 Yıl Garanti
          </h4>
          <!-- <p class="card-text">
            Cotton candy gingerbread cake I love sugar plum I love sweet croissant.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
