<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-e-commerce-shop'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>


      <b-row class="breadcrumbs-top">
        <b-col cols="12" >
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb >
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index" :to="{name:'apps-e-commerce-shop',params:{category: item}}" :style="item==product.categoryName? 'font-weight: bold':'font-weight: normal'">
                {{ item }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
      <p/>    

    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex justify-content-top mb-2 mb-md-0"
          >
            <div>
              <b-carousel
              id="carousel-example-generic"
              controls
              indicators
              img-width="400"
              img-height="280"
              class="d-flex"
              >
              <div v-for="(image, index) in product.images" :key="index" >
              <b-carousel-slide :img-src="image" fluid :alt="`Image of ${product.name}`"  />
              </div>
              </b-carousel>
              <!-- <b-img
                :src="product.image"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
                style="width:400px;height:380px"
              /> -->
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >

            <!-- Product Name -->
            <h4>{{ product.name }}</h4>
             <!-- Product Brand -->
            <b-card-text class="item-company mb-0" >
              <!-- <span>by</span> -->
              <b-link class="company-name" :to="{name:'apps-e-commerce-shop-brand',params:{brand: product.brand}}">
                {{ product.brand }}
              </b-link>
            </b-card-text>
            <h5>{{ product.productCode }}</h5>
            

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              
              <h4 class="item-price mr-2" style="text-decoration:line-through">
                {{ product.prices.originalPrice }}
              </h4>
              <h4 class="item-price mr-1">
                {{ product.price }}
              </h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
                <li v-if="product.ratingScore">{{product.ratingScore.totalCount ||''}} değerlendirme</li>
              </ul>
            </div>

            <!-- Avability -->
            <b-card-text>{{$t('instock')}} - <span class="text-success" v-if="product.hasStock">{{$t('mevcut')}}</span><span class="text-danger" v-else>{{$t('Tükendi')}}</span></b-card-text>
            <hr>

            <!-- Colors -->
            <div class="product-color-options" v-show="product.colors.length">
              <h6 v-show="product.colors.length">{{$t('Colors')}}</h6>
              <ul class="list-unstyled mb-0" fluid>
                <li
                  v-for="(color,index) in product.colors"
                  :key="index"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color.name}"
                  @click="selectedColor = color.name;yenile(color.id)"
                >
                  <div
                    class="color-option"
                    :class="`b-primary`"
                  >
                  
                  
                <b-img
                :src="color.imageUrl"
                :alt="`Image of ${color.name}`"
                class="product-img"
                fluid
                style="width:80px;height:100px"
                thumbnail
                />
                <div class="d-flex align-items-center justify-content-center" style="font-size:7px">{{color.name}}</div>
                    <!-- <div
                      class="filloption"
                      :class="`bg-${color}`"
                    /> -->
                  </div>
                </li>
              </ul>
            </div>
            <!-- Sizes -->
            <div class="product-color-options">
              <h6>{{$t('Bedenler')}}</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="(color,index) in product.variants"
                  :key="index"
                  class="d-inline-block"
                  :class="{'selected': selectedSize === color.attributeValue }"
                  @click="selectedSize = color.attributeValue"
                >
                  <div
                    class="color-option"
                    :class="`b-primary`"
                  >
                  <b-button
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  :variant="color.stock<=0? 'secondary':'outline-warning'"
                  :pressed="selectedSize === color.attributeValue"
                  :disabled="color.stock<=0"
                  >
                  <span :style="color.stock<=0? 'text-decoration:line-through':'strong'">{{color.attributeValue}}</span>
                  </b-button>
                
                  <!-- <span>{{color.attributeValue}}</span> -->
                    <!-- <div
                    thumbnail
                      class="filloption"
                      :class="`bg-${color}`"
                    /> -->
                  </div>
                </li>
              </ul>
            </div>

            <hr>

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="handleCartActionClick(product)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>{{ product.isInCart ? $t('viewincart') : $t('addtocart') }}</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="toggleProductInWishlist(product)"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50"
                  :class="{'text-danger': product.isInWishlist}"
                />
                <span>Favori</span>
              </b-button>
              <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [ 'FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon','MessageCircleIcon' ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <hr>

            <b-row>
              <app-collapse accordion type="margin" style="width:100%">
                <app-collapse-item title="Ürün Bilgileri" isVisible style="width:100%">
            <!-- Product Description -->
            
            <b-card-body>
            <b-card-text>{{ product.description }}</b-card-text>
            <!-- Product description -->
            <b-card-text v-for="(description,index) in product.contentDescriptions" :key="index"><li>{{description.description}}</li></b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <ul class="product-features list-unstyled">
              <li v-if="product.hasFreeShipping">
                <feather-icon icon="ShoppingCartIcon" />
                <span>{{$t('ucretsizkargo')}}</span></li>
              
            </ul>
            </b-card-body>
            
                </app-collapse-item>
                <app-collapse-item  title="Teknik Bilgiler" v-if="product.attributes.length" style="width:100%">
              
              
              <b-table striped hover :items="product.attributes" :fields="['key','value']" responsive="xl">
              </b-table>
              
              </app-collapse-item>
                <app-collapse-item :title="'Değerlendirmeler' + ' (' +product.productReviews.length + ')'" v-if="product.productReviews.length" style="width:100%" variant="warning">
                  <template #title>
                     <strong>Yorumlar</strong> <b-badge pill variant="warning"> {{product.productReviews.length}} </b-badge>
                  </template>
                  <!-- user commnets -->
            <div v-show="product.productReviews.length" >
              
                <app-timeline v-for="productView in product.productReviews" :key="productView.id">
                  <hr>
                  <app-timeline-item
                    :title="productView.userFullName"
                    :subtitle="productView.comment"
                    :time="productView.commentDateISOtype"
                    icon="StarIcon"
                    variant="warning"
                  ></app-timeline-item>
                </app-timeline>
              
            </div>
                </app-collapse-item>
            </app-collapse>
            </b-row>

            
            
            

            <!-- user commnets -->
            <!-- <b-media
              v-for="productView in product.productReviews"
              :key="productView.id"
              no-body
            >
              <b-media-aside>
                <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= productView.rate}, star <= productView.rate ? 'text-warning' : 'text-muted']"
                  />
                </li>
                
              </ul>
                <b-avatar
                  size="60"
                  :src="user.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder">
                  {{ productView.userFullName }}
                </h6>
                <b-card-text>
                  {{ productView.comment }}
                </b-card-text>
              </b-media-body>
            </b-media> -->

          </b-col>
        </b-row>
      </b-card-body>
      

      <!-- Static Content -->
      <!-- <e-commerce-product-details-item-features /> -->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <e-commerce-product-details-related-products :data="product" />
    </b-card>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert, BCarousel, BCarouselSlide, BBadge, BTable , BTabs,BTab, BBreadcrumb, BBreadcrumbItem
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import { useEcommerceUi } from '../useEcommerce'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BBadge,
    BTable,
    BTabs,
    BTab,
    BBreadcrumb,
    BBreadcrumbItem,
    AppCollapse,
    AppCollapseItem,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,

    //timeline
    AppTimeline,
    AppTimelineItem
  },
  methods:{
    yenile(productId){
      store.dispatch('app-ecommerce/fetchProduct', { productId }).then(response => {
        this.product = response.data.product
        this.selectedSize=undefined
        //window.scrollTo(0, 100)
        //this.selectedColor = this.product.color
        })
      
    }

  },
  
  
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const product = ref(null)
    // UI
    const selectedColor = ref(null)
    const selectedSize = ref(null)
    const breadcrumb = ref(null)

    // Remote Data
    const fetchProduct = () => {
      // Get product  id from URL
      const { route } = useRouter()
      const productSlug = route.value.params.slug
      const productId = productSlug.substring(productSlug.lastIndexOf('-') + 1)

      store.dispatch('app-ecommerce/fetchProduct', { productId })
        .then(response => {
          product.value = response.data.product
          selectedColor.value = response.data.product.color
          selectedSize.value = response.data.product.winnerVariant
          //console.log(product.value.categoryHierarchy)
          breadcrumb.value = product.value.categoryHierarchy.split('/')
          //console.log(breadcrumb)
          
        })
        .catch(error => {
          if (error.response.status === 404) {
            product.value = undefined
            selectedColor = undefined
            selectedSize = undefined
          }
        })
        // axios({method:'GET',url:'https://public.trendyol.com/discovery-web-productgw-service/api/productDetail/'+ productId + '?storefrontId=1&culture=tr-TR&userId=0' ,data: {storefrontId:1,culture:'tr-TR',userId:0},headers:{'Content-Type': 'application/json, text/plain, */*','Accept':'application/json, text/plain, */*','Access-Control-Allow-Origin':'https://www.trendyol.com',"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS","Access-Control-Allow-Headers": "*","accept-encoding": "gzip, deflate, br","Origin": "https://www.trendyol.com","Referer": "https://www.trendyol.com/"}}).then((response) => {
        //   console.log(response);
        // })
    }

    

    fetchProduct()


    return {

      // Fetched Product
      product,
      breadcrumb,

      // UI
      selectedColor,
      selectedSize,
      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
